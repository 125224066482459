import React, { useEffect, useMemo, useState } from 'react';
import { styled } from '@compiled/react';
import { GridColumn } from '@atlaskit/page';
import type { GadgetData } from '@atlassian/jira-dashboard-common/src/types.tsx';
import {
	type RenderFunctionOrNode,
	createRendererContext,
} from '@atlassian/jira-dashboard-internal-common/src/utils/context/index.tsx';
import { groupByColor } from '../../utils/index.tsx';

export type GadgetRenderProps = {
	gadget: GadgetData;
};
const { createRenderFunction: createGadgetRender } = createRendererContext<GadgetRenderProps>();

type WallboardColumnProps = {
	gadgets: GadgetData[];
	columnWidth: number;
	children: RenderFunctionOrNode<GadgetRenderProps>;
	dashboardId: string;
};

const GADGET_CYCLE_PERIOD = 10000;

export const WallboardColumn = ({
	gadgets,
	columnWidth,
	children,
	dashboardId,
}: WallboardColumnProps) => {
	const renderGadget = createGadgetRender(children);
	const gadgetsGroups = useMemo(() => groupByColor(gadgets), [gadgets]);
	const initGadgetIndexInGroup = useMemo(() => gadgetsGroups.map(() => 0), [gadgetsGroups]);
	const [currGadgetIndexInGroup, setCurrentGadgetIndexInGroup] = useState(initGadgetIndexInGroup);
	useEffect(() => {
		// If the dashboardId has changed (i.e. we're on slide show), we reset the index of the column
		setCurrentGadgetIndexInGroup(initGadgetIndexInGroup);
	}, [dashboardId, initGadgetIndexInGroup]);

	useEffect(() => {
		// We don't cycle through gadgets if there is 1 or less
		if (gadgets.length < 2) {
			// Replace with lodash/noop
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			return () => {};
		}

		const interval = setInterval(() => {
			setCurrentGadgetIndexInGroup((prevIndexArray): number[] =>
				prevIndexArray.map(
					(prevIndex, groupIndex) => (prevIndex + 1) % gadgetsGroups[groupIndex].length,
				),
			);
		}, GADGET_CYCLE_PERIOD);
		return () => clearInterval(interval);
	}, [gadgets.length, gadgetsGroups]);

	return (
		<GridColumn medium={columnWidth}>
			<ColumnWrapper>
				{gadgetsGroups.map((gadgetsGroup, groupIndex) =>
					gadgetsGroup.map((gadget, gadgetIndex) => (
						<WallboardGadgetWrapper
							key={gadget.id}
							isHidden={currGadgetIndexInGroup[groupIndex] !== gadgetIndex}
							row={groupIndex + 1}
						>
							{renderGadget({ gadget })}
						</WallboardGadgetWrapper>
					)),
				)}
			</ColumnWrapper>
		</GridColumn>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WallboardGadgetWrapper = styled.div<{ isHidden?: boolean; row: number }>(
	{
		transition: 'opacity 1s',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		gridRowStart: ({ row }) => row,
		gridColumnStart: 1,
		height: '100%',
		width: '100%',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isHidden }) => (isHidden ? { opacity: 0 } : { opacity: 1 }),
);

// this will overlay gadgets on top of each other and control the visibility via opacity
// ref https://stackoverflow.com/questions/18027751/overlay-divs-without-absolute-position
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColumnWrapper = styled.div({
	display: 'grid',
	// This is set as minmax(0, 1fr) as gadgets would blowout in their width without it
	// https://css-tricks.com/preventing-a-grid-blowout/
	gridTemplateColumns: 'minmax(0, 1fr)',
});
