import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import { Grid } from '@atlaskit/page';
import { token } from '@atlaskit/tokens';
import { ROUTE_NAMES_DASHBOARD_VIEW } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import LoginRedirect from '@atlassian/jira-common-navigation/src/login-redirect/index.tsx';
import {
	DASHBOARD_WALLBOARD_VIEW_SOURCENAME,
	GADGET_DISPLAY,
	VIEW_DASHBOARD_WALLBOARD_READONLY_EXPERIENCE,
} from '@atlassian/jira-dashboard-common/src/constants.tsx';
import { GenericLoadingView } from '@atlassian/jira-dashboard-internal-common/src/common/generic-loading-view/index.tsx';
import { DashboardControllerContextConsumer } from '@atlassian/jira-dashboard-internal-common/src/controllers/dashboard/context.tsx';
import { DashboardController } from '@atlassian/jira-dashboard-internal-common/src/controllers/dashboard/main.tsx';
import { DashboardPageContainer } from '@atlassian/jira-dashboard-internal-common/src/ui/container/main.tsx';
import { getColumnWidth } from '@atlassian/jira-dashboard-internal-common/src/ui/dashboard-content/layout/utils/index.tsx';
import { DashboardContentContainer } from '@atlassian/jira-dashboard-internal-common/src/ui/dashboard-content/main.tsx';
import { toColumns } from '@atlassian/jira-dashboard-internal-common/src/utils/layout.tsx';
import { useConnectGadgetTitles } from '@atlassian/jira-dashboard-internal-common/src/utils/use-gadget-title/index.tsx';
import { WallboardPermissionErrorPageAsync } from '@atlassian/jira-error-pages/src/async.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useRouteByName } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { generatePath, useRouterActions } from '@atlassian/react-resource-router';
import { useDashboardsWallboardSlideShow } from './hooks/index.tsx';
import { WallboardColumn } from './ui/column/index.tsx';
import { WallboardGadget } from './ui/gadget/index.tsx';
import KeyboardShortcuts from './ui/keyboard-shortcuts.tsx';
import messages from './ui/messages.tsx';
import { UnconfiguredGadgetWallboardFallback } from './ui/unconfigured-gadget-wallboard-fallback/index.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => {
	const {
		data: dashboards,
		error,
		loading,
		currentDashboardIndex,
	} = useDashboardsWallboardSlideShow();
	const { push } = useRouterActions();
	const { path: viewPath } = useRouteByName(ROUTE_NAMES_DASHBOARD_VIEW) ?? {};
	const onQuittingWallboard = useCallback(
		() =>
			viewPath &&
			dashboards &&
			dashboards[currentDashboardIndex] &&
			dashboards[currentDashboardIndex].id &&
			push(generatePath(viewPath, { dashboardId: dashboards[currentDashboardIndex].id })),
		[push, dashboards, currentDashboardIndex, viewPath],
	);
	const { formatMessage } = useIntl();

	const { registerConnectGadget, unregisterConnectGadget } = useConnectGadgetTitles();

	if (__SERVER__ || loading) {
		return <GenericLoadingView />;
	}
	if (dashboards == null || error != null) {
		// here we re-use single dashboard's DashboardContentContainer to display error view and fire analytics events
		// TODO this still won't handle empty dashboards array. It will still display a blank page.
		return (
			<DashboardContentContainer
				analyticsExperience={VIEW_DASHBOARD_WALLBOARD_READONLY_EXPERIENCE}
				resourceData={{ data: null, error, loading }}
				permissionErrorFallback={<WallboardPermissionErrorPageAsync />}
			>
				<></>
			</DashboardContentContainer>
		);
	}

	return (
		<WallboardPageWrapper>
			<WallboardSlideShowWrapper>
				<UFOSegment name="dashboard-wallboard">
					{dashboards &&
						!__SERVER__ &&
						dashboards.map((data, index) => (
							<SingleWallboardWrapper key={index}>
								<DashboardPageContainer
									analyticsSourceName={DASHBOARD_WALLBOARD_VIEW_SOURCENAME}
									analyticsExperience={VIEW_DASHBOARD_WALLBOARD_READONLY_EXPERIENCE}
									documentTitle={data?.title ?? formatMessage(messages.pageTitle)}
									unauthenticatedFallback={<LoginRedirect />}
									resourceData={{ data, error, loading }}
								>
									<DashboardContentContainer
										analyticsExperience={VIEW_DASHBOARD_WALLBOARD_READONLY_EXPERIENCE}
										resourceData={{ data, error, loading }}
										permissionErrorFallback={<WallboardPermissionErrorPageAsync />}
									>
										<KeyboardShortcuts onPressedEsc={onQuittingWallboard} />
										<WallboardContentWrapper isHidden={currentDashboardIndex !== index}>
											{data && data.layout && data.gadgets ? (
												<DashboardController id={data.id} automaticRefreshMs={null}>
													<Grid layout="fluid">
														{toColumns(data.gadgets, data.layout).map(
															(columnGadgets, columnIndex) => (
																<WallboardColumn
																	key={columnIndex}
																	gadgets={columnGadgets}
																	columnWidth={getColumnWidth(
																		columnIndex,
																		data.layout,
																		GADGET_DISPLAY.NORMAL,
																	)}
																	dashboardId={data.id}
																>
																	{({ gadget }) => (
																		<DashboardControllerContextConsumer>
																			{({ refreshId }) => (
																				<WallboardGadget
																					gadget={gadget}
																					dashboardId={data.id}
																					refreshId={refreshId}
																					unconfiguredFallback={
																						<UnconfiguredGadgetWallboardFallback id={gadget.id} />
																					}
																					onRegisterConnectGadget={registerConnectGadget}
																					onUnregisterConnectGadget={unregisterConnectGadget}
																				/>
																			)}
																		</DashboardControllerContextConsumer>
																	)}
																</WallboardColumn>
															),
														)}
													</Grid>
												</DashboardController>
											) : null}
										</WallboardContentWrapper>
									</DashboardContentContainer>
								</DashboardPageContainer>
							</SingleWallboardWrapper>
						))}
				</UFOSegment>
			</WallboardSlideShowWrapper>
		</WallboardPageWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WallboardPageWrapper = styled.div({
	position: 'fixed',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WallboardSlideShowWrapper = styled.div({
	display: 'grid',
	gridTemplateColumns: '1fr',
	paddingTop: token('space.250', '20px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SingleWallboardWrapper = styled.div({
	gridRowStart: 1,
	gridColumnStart: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const WallboardContentWrapper = styled.div<{ isHidden?: boolean }>(({ isHidden }) =>
	isHidden ? { opacity: 0 } : { opacity: 1 },
);
